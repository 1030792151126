<app-base-page-item
  [data]="data"
  [recordStatusActions]="recordStatusActions"
  [extendLockTimeAction]="extendLockTimeAction"
  [showLocked]="showLocked"
  [showCommentsButton]="editMode && viewModeService.viewMode != 'create'"
  [showStateMachineAuditTrail]="showStateMachineAuditTrail"
  [showHistoryButton]="editMode && viewModeService.viewMode != 'create'"
  [showAuditButton]="editMode && viewModeService.viewMode != 'create'"
  [showAddCommentButton]="editMode && viewModeService.viewMode != 'create'"
  [showShareButton]="editMode && viewModeService.viewMode != 'create'"
  [showRecordStatus]="editMode && viewModeService.viewMode != 'create'"
  [breadCrumb]="breadCrumb"
  [sourceCode]="itemId"
  [hasEditChanges]="hasEditChanges$ | async"
  [fromType]="fromType"
  [showCommentsButton]="true"
  [showViewSwitch]="editMode"
  [showRelations]="editMode && viewModeService.viewMode != 'create'"
  [showViewSwitch]="editMode && viewModeService.viewMode != 'create'"
  (onViewModeChange)="onViewModeChange($event)"
  [moduleKeyword]="moduleKeyword"
>
  <ng-container page-header>
    <h4 class="mb-0">
      {{ 'general.actions.' + (editMode ? viewModeService.viewMode : 'add') | translate }}
      {{ 'modules.issue.issueType.issueType' | translate }}
    </h4>
  </ng-container>

  <app-issue-types-item-form
    (formSubmit)="onSubmit($event)"
    (formSubmitSave)="onSubmit($event, 'PAGE')"
    (formSubmitInPlace)="onSubmit($event, 'NONE')"
    (cancelEvent)="onCancel($event)"
    [resetForm]="resetFormTrigger"
    (valueEditChanged)="valueEditChanged($event)"
    [formData]="data"
  ></app-issue-types-item-form>
  <ng-container below-main-card>
    @if (remediationActionTableCardContent && showLinkedTable && data && this.showRuleHandler) {
      <div class="card">
        <app-widget-table
          class=""
          [setDefaultActions]="false"
          [additionalStaticProjectionFields]="['escalataions']"
          [title]="remediationActionTableCardContent?.name"
          [cardContent]="remediationActionTableCardContent"
          [styleClass]="'relative m-0 '"
          style="height: calc(100% - 25px)"
          [pageActions]="linkedTablePageActions"
          [tableActions]="linkedTableActions"
          (onRowSelectionChange)="onRowSelection($event)"
        >
        </app-widget-table>
      </div>
    }
  </ng-container>
</app-base-page-item>
