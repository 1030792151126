import { Component } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { LocaleService } from '@core/services/locale/locale.service';
import { IssueType } from '@shared/classes/model/backend/issue';
import { IssuesMappingService } from '@shared/services/mappings/issue/issues-mapping.service';
import { ToastService } from '@shared/services/toast.service';
import { ViewModeService } from '@shared/services/view-mode.service';
import { AppInjector } from 'app/app-injector';
import {
  ContentViewDto,
  IAction,
  IssueDto,
  ItemBasePage,
  makePlural,
  MessageKeys,
  ModuleKeywordRootPath,
  ModuleKeywords,
  ModuleRoutePrefix,
  PermissionActions,
  RequestHandlerOptions,
  routeToLocaleCase,
  TargetTypeEnum,
  toCamelCase,
  toKebabCase,
} from 'app/shared/classes';
import { combineLatest, takeUntil } from 'rxjs';
import { IssueTypeDataService } from '../../services/data/issue-type-data.service';
import { IssuesDataService } from '../../services/data/issues-data.service';

@Component({
  selector: 'app-issue-types-item',
  templateUrl: './issue-types-item.component.html',
  styleUrls: ['./issue-types-item.component.scss'],
})
export class IssueTypesItemComponent extends ItemBasePage<IssueType> {
  realTimeFormData: any = {}; //Copy of form data that changes realtime when form changes

  apiOptions: RequestHandlerOptions = {
    showLoading: true,
    showMsg: false,
  };
  linkedTablePageActions: IAction[] = [
    {
      label: 'Sync Escalations',
      id: 1,
      color: 'primary',
      icon: 'pi pi-sync',
      iconPos: 'left',
      command: this.bulkUpdateEscalationsPaths.bind(this),
      permission: `${PermissionActions.Update}${ModuleKeywords.Issue}`,
      displayCommand: (data) => (this.selectedRows?.length > 0 ? true : false),
    },
  ];
  linkedTableActions: IAction[] = [
    {
      label: '',
      id: 1,
      color: 'secondary',
      icon: 'pi pi-sync',
      iconPos: 'left',
      command: this.updateEscalationsPaths.bind(this),
      permission: `${PermissionActions.Update}${ModuleKeywords.Issue}`,
      tooltipText: 'Sync Escalations',
      tooltipOptions: {
        tooltipLabel: 'Sync Escalations',
      },
      displayCommand: (row: IssueDto) => {
        console.log(row?.escalations);
        console.log(this.data?.escalations);
        return JSON.stringify(row?.escalations) !== JSON.stringify(this.data?.escalations);
      },
    },
  ];
  constructor(
    private requestService: IssueTypeDataService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private toastService: ToastService,
    public viewModeService: ViewModeService,
    public localeService: LocaleService,
    private issueMappingService: IssuesMappingService,
    private issueDataService: IssuesDataService
  ) {
    super(
      {
        moduleKeyword: ModuleKeywords.IssueType,
        routePrefix: ModuleRoutePrefix.IssueManagement,
      },
      router,
      requestService,
      toastService
    );

    combineLatest([this.activatedRoute.paramMap])
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        this.itemId = data[0].get('id');
        this.editMode = !!this.itemId;
        this.SetPageValues({
          breadCrumb: {
            items: [
              {
                label: this.localeService.translate(
                  `modules.${routeToLocaleCase(this.ModuleKeywordRootPath[this.moduleKeyword])}.${toCamelCase(ModuleKeywordRootPath[this.moduleKeyword])}`
                ),
                routerLink: [`${this.routePrefix ? this.routePrefix : toKebabCase(this.moduleKeyword)}`],
              },
              {
                label: this.localeService.translate(
                  `modules.${routeToLocaleCase(ModuleKeywordRootPath[this.moduleKeyword])}.${toCamelCase(this.moduleKeyword)}.${makePlural(toCamelCase(this.moduleKeyword))}`
                ),
                routerLink: [`/${this.routePrefix}${toKebabCase(this.moduleKeyword)}/list`],
              },
              {
                label: this.itemId
                  ? this.itemId
                  : this.localeService.translate('general.actions.add') +
                    ' ' +
                    this.localeService.translate(
                      `modules.${routeToLocaleCase(ModuleKeywordRootPath[this.moduleKeyword])}.${toCamelCase(this.moduleKeyword)}.${toCamelCase(this.moduleKeyword)}`
                    ),
                routerLink: [],
              },
            ],
          },
          itemId: this.itemId,
          fromType: TargetTypeEnum.IssueType,
        });
      });
    this.setRemediationActionsTableCardObj();
  }
  remediationActionTableCardContent: ContentViewDto;
  setRemediationActionsTableCardObj() {
    const tableModule: ModuleKeywords = ModuleKeywords.Issue;
    this.remediationActionTableCardContent = {
      // ...this.cardContent?.contentViewObject,
      name: this.localeService.translate(
        `modules.${routeToLocaleCase(ModuleKeywordRootPath[tableModule])}.${toCamelCase(tableModule)}.${makePlural(toCamelCase(tableModule))}`
      ),
      targetType: tableModule as any,
      tableViewConfig: {
        // ...this.cardContent?.contentViewObject?.drillDownTableViewConfig,
        columns: [...this.issueMappingService.tableFields],
        showActions: true, //change to show actions
        showGlobalFilter: true,
        showOperationActions: true,
        showDeleteAction: false,
        showEditAction: false,
        showLockAction: false,
        showActivateAction: false,
        showDeactivateAction: false,
        showStatusActions: true,
        showSettingsLink: false,
        showFileExportAction: true,
        showPackageExportAction: false,
        showBulkOperationAction: false,
        showReviseRelationsAction: false,
        showAddAction: false,
      },
      filterView: {
        // ...this.cardContent?.contentViewObject?.filterView,
        targetType: tableModule as any,
        filter: {
          // ...this.cardContent?.contentViewObject?.filterView.filter,
          filters: [{ property: 'issueTypeCode', operation: 'EQUAL', value: this.itemId }],
        },
      },
    };
  }
  selectedRows = [];
  showLinkedTable = true;
  onRowSelection(event) {
    this.selectedRows = [...event];
  }
  bulkUpdateEscalationsPaths() {
    this.issueDataService
      .updateEscalationPaths({
        issues: this.selectedRows?.map((x) => x?.code),
      })
      .subscribe((res) => {
        const toastService = AppInjector.get(ToastService);
        toastService.success(MessageKeys.success, MessageKeys.editedSuccessfully);
        this.showLinkedTable = false;
        setTimeout(() => {
          this.showLinkedTable = true;
        }, 5);
      });
  }
  updateEscalationsPaths(row) {
    this.issueDataService
      .updateEscalationPaths({
        issues: [row?.code],
      })
      .subscribe((res) => {
        const toastService = AppInjector.get(ToastService);
        toastService.success(MessageKeys.success, MessageKeys.editedSuccessfully);
        this.showLinkedTable = false;
        setTimeout(() => {
          this.showLinkedTable = true;
        }, 5);
      });
  }
  onUpdateItem() {
    console.log(this.data);
  }
}
